import PersonalInfoForm from "./personalInfo";
import ContactForm from "./contacts";
import WorkInfoForm from "./workInfo";
import PaymentInstructionsForm from "./paymentInstructions";
import FinancialSituationForm from "./financialSituation";
import PersonalReferenceForm from "./personalReferences";
import FulfillmentForm from "./fulfillment";
import FamilyInCaseDecease from "./familyInCaseDecease";
import FilesSelection from "./files";
import FinancialInfoForm from "./financialInfo";
import Documents from "./documents";
import Comment from "./comment";
import FinancingDataForm from "./financingData";
import SavingsDataForm from "./savingsInfo";
import CertificateDataForm from "./certificateData";
import complianceDataForm from "./complianceData";
import AffiliationTermsForm from "./affiliationTerms";

export default () => ({
  personalInfo: {
    component: PersonalInfoForm,
    label: "Datos generales",
    fields: [
      "fullName",
      "identificationType",
      "identificationNumber",
      "gender",
      "birthDate",
      "nationality",
      "profession",
      "accountPurpose",
    ],
    rFields: ["fullName", "identificationType", "identificationNumber"],
  },
  contact: {
    component: ContactForm,
    label: "Datos de residencia",
    fields: [
      "country",
      "region",
      "province",
      "city",
      "sector",
      "reference",
      "cellPhone",
      "homePhone",
      "additionalPhone",
      "owner_email",
    ],
    rFields: [],
  },
  workInfo: {
    component: WorkInfoForm,
    label: "Datos laborales",
    fields: [
      "entryDate",
      "salary",
      "institutionTime",
      "paymentType",
      "workType",
      "sessionType",
    ],
    rFields: [],
  },
  financingData: {
    component: FinancingDataForm,
    label: "Datos de Financiamiento",
    fields: [
      "amount",
      "term",
      "settlementDisbursment",
      "paymentDay",
      "creditType",
      "houseInfo",
      "numberOfDependents",
      "educationLevel",
      "precalification",
      "periodicity",
      "quotation",
      "membershipFinanced",
      "savingsFinanced",
    ],
    rFields: [],
  },
  savingsData: {
    component: SavingsDataForm,
    label: "Datos de Ahorro",
    fields: [
      "firstDeposit",
      "infantSavingQuota",
      "infantName",
      "infantAge",
      "plannedGoal",
      "plannedQuota",
      "plannedTerm",
      "plannedFrequency",
      "plannedPaymentDay",
      "scndPlannedPaymentDay",
    ],
    rFields: [],
  },
  certificateData: {
    component: CertificateDataForm,
    label: "Datos de Certificado",
    fields: ["certAmount", "certTerm", "interest", "certWayToPay", "payType"],
    rFields: [],
  },
  affiliationTerms: {
    component: AffiliationTermsForm,
    label: "Términos de Afiliación",
    fields: [
      "monthlyDiscount",
      "discountObligations",
      "wayToPay",
      "distributionPanel",
      "initialDeposit",
      "amountToSave",
      "cuotaOnSight",
      "cuotaContributions",
    ],
    rFields: [],
  },
  complianceData: {
    component: complianceDataForm,
    label: "Datos de Cumplimiento",
    fields: [
      "transactionalBehavior",
      "personKind",
      "category",
      "PepPosition",
      "PepDate",
      "PepInstitution",
      "PepRelation",
      "affidavitSources",
    ],
    rFields: [],
  },
  fileSelection: {
    component: FilesSelection,
    label: "Archivos adjuntos de solicitud",
    fields: ["files"],
    rFields: [],
  },
  documents: {
    component: Documents,
    label: "Documentación legal relevante",
    rFields: [],
  },
  commentSection: {
    component: Comment,
    noWrapper: true,
    rFields: [],
  },
});
