import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { ClearAll, Description } from "@material-ui/icons";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import accountNumberMaskInput from "src/components/TextMaskInputComponents/accountNumberMaskInput";
import { bankAccountTypes, getFunctionalDiscount, getRiskMatrix, rohiAccountsType, translations } from "src/utils";
import { makeid } from "../../../components/SharedComponents/CustomHooks";
import {
  convertSavingsFormToField,
  formattedFieldsData,
  statusColors,
} from "../GeneralMethods";
import {
  errorHandling,
  validateRequiredFields,
} from "./../../../components/SharedComponents/CustomHooks";
import {
  ConfirmDialog,
  DialogFooter,
  DialogHeader,
} from "./../../../components/SharedComponents/Dialogs";
import {
  AutocompleteField,
  SearchComponent,
} from "./../../../components/SharedComponents/Fields";
import {
  fetchInstitutions,
  fetchInterests,
  fetchPrecalification,
  uploadFilesLead,
} from "./../../../utils/axios";
import Sections from "./../FormSections/index";
import Layout from "./../FormSections/layout";

export function ConfirmDialogComponent(props) {
  const data = props.data.data;
  let dialogConfirmContent = "";
  switch (props.data.msg) {
    case "proceed-delete":
      dialogConfirmContent = `La transacción será eliminada.`;
      break;
    case "proceed-delete-file":
      dialogConfirmContent = `El archivo será eliminado de la lista. Una vez guardada la solicitud, no podrá recuperarlo.`;
      break;
    case "proceed-delete-multiple":
      dialogConfirmContent = `¿Está seguro que desea eliminar todas las transacciones seleccionadas?`;
      break;
    case "proceed-auth":
      dialogConfirmContent = `¿Está seguro que desea autorizar la solicitud: ${data.reference ||
        "----"}?`;
      break;
    case "proceed-close":
      dialogConfirmContent = `¿Desea cerrar la pantalla?`;
      break;
    default:
      dialogConfirmContent = "...";
  }
  return (
    <ConfirmDialog
      open={props.open}
      handleToggle={props.handleToggle}
      confirmCallback={data.callback}
      data={dialogConfirmContent}
    />
  );
}

export function SelectSavingsAccDialog(props){
  const { open, handleClose, createTicket, data } = props;
  const [selectedAccount, setSelectedAccount] = useState(null);

  const handleAccept = () => {
    const { formattedData, lead, sectionErrors } = data;
    const payload = {
      formattedData,
      lead,
      sectionErrors,
      savingAccount: selectedAccount,
    };
    createTicket(payload);
    handleClose(); // Cierra el diálogo
  };

  return (
    <Dialog open={open}>
      <DialogContent>
        <InputLabel>Seleccionar cuenta de desembolso ROHI</InputLabel>
        <br/>
        <Select
          value={selectedAccount}
          onChange={(e) => setSelectedAccount(e.target.value)}
          fullWidth
          variant="outlined"
          margin="dense"
        >
          {data?.lead?.savingsAccountForm.map((acc) => (
            <MenuItem key={acc.id} value={acc}>
              {`${rohiAccountsType.find(t => t.value === acc.type)?.label || acc.type} - ${acc.accountNumber}`}
            </MenuItem>
          ))}
        </Select>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleAccept} disabled={!selectedAccount}>
          Aceptar
        </Button>
        <Button onClick={handleClose}>Cancelar</Button>
      </DialogActions>
    </Dialog>
  );
}

export function LeadsDialog(props) {
  let saveRef = useRef(null);
  let parentDialogFields = useRef({});
  const [leadData, setLeadData] = useState(props.data);
  // const [showCompleteBtn, setShowCompleteBtn] = useState(false);
  const [currentState, setCurrentState] = useState(
    props.data?._state || props.data?.state
  );
  const [leadStates, setLeadStates] = useState([]);
  const [generalParentData, setGeneralParentData] = useState({});
  const [disabledLead, setDisabledLead] = useState(false);
  const [sectionErrors, setSectionErrors] = useState({
    errors: null,
    formatted: null,
  });
  const [institution, setInstitution] = useState({});
  const [bankInfo, setBankInfo] = useState({});
  const disable = ["complete", "canceled"].includes(
    leadData?._state || leadData?.state
  );

  const handleSaveRef = () => {
    saveRef.current.click();
  };
  // const showCompleteLeadBtn = (states) => {
  //     const show = states.find(item => item.state === 'signature_completed' && !disable);
  //     setShowCompleteBtn(Boolean(show));
  // }
  const closeDialog = () => {
    props.handleToggleDialog("leads");
    props.handleSimpleDialog(undefined);
  };
  const RiskMatrix = async () => {
    props.setLoading(true);
    await getRiskMatrix(
      leadData?.uniqueId_owner,
      true,
      props.token,
      leadData?.fullName
    );
    props.setLoading(false);
  };
  const stateActions = () => {
    return (
      <Grid container direciton="row" spacing={2}>
        {leadStates.map(
          (ls, index) =>
            ls.hasPermission &&
            ls.show && (
              <Button
                key={`leads-action-${index}`}
                variant="outlined"
                style={{
                  fontSize: 11,
                  color: ls.color,
                  borderColor: ls.color,
                  backgroundColor: "#fff",
                  margin: "1rem 0 1rem 32px",
                }}
                disableElevation
                size="medium"
                onClick={() =>
                  props.handleSimpleDialog("confirm-dialog", "proceed-auth", {
                    callback: () =>
                      props.handleStates(props.data, {
                        institution,
                        setSectionErrors,
                        setLeadData,
                        order: ls.order,
                        nextOrder: leadStates.find(
                          (item) => item.order === ls.order + 1
                        )?.state,
                        currBtnState: ls.state,
                      }),
                    reference: props.data?.referenceCode,
                  })
                }
              >
                {ls.label}
              </Button>
            )
        )}
        {statusColors.find((item) => item.state == leadData?._state)?.order >=
          4 && (
          <Button
            size="medium"
            variant="outlined"
            disableElevation
            style={{
              borderRadius: 3,
              backgroundColor: green[500],
              color: "#fff",
              fontSize: 11,
              margin: "1rem 0 1rem 32px",
            }}
            onClick={RiskMatrix}
          >
            GENERAR MATRIZ DE RIESGO
            <Description style={{ fontSize: 17, marginLeft: ".5rem" }} />
          </Button>
        )}
      </Grid>
    );
  };

  const saveBtn = () => {
    return (
      <Button
        variant="contained"
        className={
          disabledLead ? "action-method-btn disabled" : "action-method-btn save"
        }
        style={{ marginRight: 24 }}
        disableElevation
        size="small"
        onClick={handleSaveRef}
        disabled={disabledLead}
      >
        guardar
      </Button>
    );
  };
  useEffect(() => {
    if (leadData) {
      const states = props.leadStates(leadData);
      // showCompleteLeadBtn(states);
      setLeadStates([...states]);
      setDisabledLead(disable);
    }
  }, [leadData]);
  useEffect(() => {
    if (props.data) {
      setLeadData({ ...props.data });
    }
  }, [props.data]);
  return (
    <Dialog
      fullWidth
      maxWidth="md"
      onClose={(e, reason) => reason === "backdropClick" && e.preventDefault()}
      className="dialog-container"
      open={props.open}
    >
      <DialogHeader
        dialogTitle={props.dialogTitle}
        // handleClick={
        //     props.handleToggleDialog.bind(this, 'leads')
        // }
        handleClick={props.handleSimpleDialog.bind(
          this,
          "confirm-dialog",
          "proceed-close",
          { callback: closeDialog }
        )}
      />
      <DialogContent style={{ margin: "2% 0" }}>
        <LeadsDialogContent
          {...props}
          saveRef={saveRef}
          parentDialogFields={parentDialogFields}
          generalParentData={generalParentData}
          institution={institution}
          setInstitution={setInstitution}
          bankInfo={bankInfo}
          setBankInfo={setBankInfo}
          data={leadData}
          disabled={disabledLead}
          setDisabledLead={setDisabledLead}
          sectionErrors={sectionErrors}
          setSectionErrors={setSectionErrors}
        />
      </DialogContent>
      <DialogFooter
        primaryAction={saveBtn()}
        secondaryActions={props.data && stateActions()}
      />
    </Dialog>
  );
}

export function LeadsDialogContent(props) {
  let fields = useRef({});
  let requiredFields = useRef([]);
  const ignoredProperties = [
    "files",
    "blacklist",
    "addressFile",
    "identificationFile",
  ];
  const [currentStatus, setCurrentStatus] = useState({});
  const [errorFields, setErrorFields] = useState({});
  const [error, setError] = useState(false);
  const [expand, setExpand] = useState("");
  const [hiddenSections, setHiddenSections] = useState({});
  const [leadsData, setLeadsData] = useState(props.data);

  const sections = Sections();
  const valRequiredFields = (type) => {
    return validateRequiredFields(
      fields.current,
      requiredFields.current,
      errorFields,
      setErrorFields,
      setError,
      type
    );
  };

  useEffect(()=> {
    setLeadsData(props.data)
  }, [props.data])

  const modifyStatus = (event, value) => {
    handleFieldChange({ ["_state"]: value.state });
    setCurrentStatus(value);
  };

  const getFiltersOptions = (option) => {
    let data = option.results ? option.results : option;
    return data ? data : [];
  };

  const handleFieldChange = (data) => {
    fields.current = { ...fields.current, ...data };
    props.parentDialogFields.current = {
      initialAccountSaving: fields.current?.initialAccountSaving,
    };
  };

  const getStatus = () => {
    const coloredLabel = statusColors.find(
      (item) =>
        item.state === (props.data?._state || props.data?.state) ||
        item.state === "default"
    );
    setCurrentStatus(coloredLabel);
  };

  const handleEditRow = () => {
    if (props.data.savingsAccountForm?.length) {
      convertSavingsFormToField(props.data.savingsAccountForm, props.data);
    }
    setLeadsData({ ...props.data });
    handleFieldChange(props.data);
  };

  const cleanData = (data) => {
    for (const property in data) {
      if (
        !(Array.isArray(data[property]) && !data[property].length) &&
        !ignoredProperties.includes(property)
      ) {
        if (typeof data[property] === "undefined" || data[property] === "") {
          delete data[property];
        } else if (typeof data[property] === "object") {
          cleanData(data[property]);
          if (!Object.keys(data[property] || {}).length) {
            delete data[property];
          }
        }
      }
    }
  };

  const createFilesLeads = async (endpoints) => {
    props.setLoading(true);
    return await axios
      .all(endpoints)
      .then(
        axios.spread((...responses) => {
          const files = responses.map((item) => item.data);
          props.setLoading(false);
          if (!props.modify) {
            props.getLeads();
            props.handleSimpleDialog("success-dialog");
          }
          if (props.modify) {
            return files;
          }
          return false;
        })
      )
      .catch((errors) => {
        props.setLoading(false);
        errorHandling(errors, props.handleSimpleDialog);
      });
  };

  const handleUploadFiles = async (uniqueId) => {
    const filteredFiles = fields.current.files.filter((file) => !file.uniqueId);
    if (filteredFiles.length) {
      let endpoints = filteredFiles.map((file) => {
        let formData = new FormData();
        formData.append("file", file);
        formData.append("name", `leads-${makeid(10)}`);
        return uploadFilesLead(props.token, uniqueId, formData);
      });
      return await createFilesLeads(endpoints);
    } else if (!props.modify) {
      props.getLeads();
      props.handleSimpleDialog("success-dialog");
    }
    return [];
  };

  const handleChangecomment = (e) => {
    setComment(e.target.value);
    fields.current.comment = e.target.value;
  };

  const handleSaveBtn = async () => {
    if (valRequiredFields("all")) {
      props.handleSimpleDialog(
        "simple-dialog",
        `Favor llenar todos los campos requeridos.`
      );
      return;
    }
    const data = formattedFieldsData(
      { ...fields.current },
      props.modify ? props.data : null,
      { institution: props.institution, bankInfo: props.bankInfo }
    );

    // cleanData(data);
    // due to backend issue, delete identification property from payload.

    // WHEN UPDATING, REFRESH ALL DATA IN THE DIALOG.
    // if(props.modify && data.owner.identificationNumber === props.data.identificationNumber) {
    //     delete data.owner.identificationNumber;
    // }

    const action = props.modify ? "update" : "create";
    if (props.modify && fields.current.files?.length) {
      const files = await handleUploadFiles(data.uniqueId);
      const newFiles = fields.current.files
        .concat(files)
        .map((item) => item.uniqueId);
      data.owner.files = [...newFiles];
      props.saveCreateLead(data, action, props.setSectionErrors);
    } else if (!props.modify) {
      const uniqueId = await props.saveCreateLead(
        data,
        action,
        props.setSectionErrors
      );
      if (uniqueId && fields.current.files?.length) {
        handleUploadFiles(uniqueId);
      } else if (uniqueId) {
        props.getLeads();
        props.handleSimpleDialog("success-dialog");
      }
    } else {
      props.saveCreateLead(data, action, props.setSectionErrors);
    }
  };

  const generateDistributionData = (inscription, currFields, isAffiliated) => {
    return inscription.map((inst) => {
      let firstDiscount;
      const affiliated = currFields.membershipFinanced ? 0 : inst.affiliated;
      const notAffiliated = currFields.membershipFinanced
        ? 0
        : inst.notaffiliated;
      if (
        parseFloat(currFields.initialDeposit) > 0 &&
        inst.reason === "onSight"
      ) {
        firstDiscount = isAffiliated
          ? parseFloat(currFields.initialDeposit) + affiliated
          : parseFloat(currFields.initialDeposit) + notAffiliated;
      } else {
        firstDiscount = isAffiliated ? affiliated : notAffiliated || 0;
      }

      const secuentDiscount = isAffiliated
        ? inst.affiliateRohiQuota || 0
        : inst.notAffiliateRohiQuota || 0;
      const warranty = currFields.membershipFinanced
        ? isAffiliated
          ? inst.affiliated
          : inst.notaffiliated
        : undefined;

      return {
        name: inst.reason,
        firstDiscount: firstDiscount,
        secuentDiscount: secuentDiscount,
        warranty: warranty,
      };
    });
  };

  const calcDistributionChart = async () => {
    try {
      const currFields = fields?.current;
      const infant = currFields.savingsAccountForm?.find(item => item.type === "youth")
      const params = {
        calculatorType: "membership_breakddown",
        token: props.token,
      };
      let headers = [
        { value: "purpose", label: "Propósito" },
        { value: "first", label: "Aporte inicial" },
        { value: "monthly", label: "Aporte Mensual" },
      ];
      const warrantyVal = parseFloat(currFields.amount) * 0.05 || 0;
      const res = await fetchInterests(params);
      const inscription = res?.data[0]?.description;
      let distributionData = [];

      const isAffiliated = props.institution?.state === "affiliate";
      distributionData = generateDistributionData(
        inscription,
        currFields,
        isAffiliated
      );

      if (
        currFields.amount > 0 ||
        distributionData.some((item) => item.warranty > 0)
      ) {
        headers.push({ value: "warranty", label: "Descuento Unico Préstamo" });
      }

      if (currFields.amount > 0) {
        distributionData.push({
          name: "Ahorro Funcional",
          firstDiscount: currFields.savingsFinanced ? 0 : warrantyVal,
          secuentDiscount: await getFunctionalDiscount(
            parseFloat(currFields.amount), props.token
          ),
          warranty: currFields.savingsFinanced ? warrantyVal : 0
        });
      }

      if (currFields.infantSavingQuota || infant?.quota) {
        distributionData.push({
          name: "Ahorro Infantil",
          firstDiscount: currFields.infantSavingQuota || infant?.quota,
          secuentDiscount: currFields.infantSavingQuota || infant?.quota,
        });
      }

      if (currFields.plannedQuota) {
        distributionData.push({
          name: "Ahorro Planificado",
          firstDiscount: currFields.plannedQuota,
          secuentDiscount: currFields.plannedQuota,
        });
      }

      const distributionPanel = {
        headers: headers,
        distributionData: distributionData,
      };

      return distributionPanel;
    } catch (error) {
      console.error("Error al obtener pool de tasa.", error);
    }
  };


  const calcPrecalification = async () => {
    const currFields = fields?.current;

    const params = {
      salary: parseFloat(currFields.salary),
      dateOfHire: currFields.entryDate,
      numberOfDependents:
        currFields.numberOfDependents?.value || currFields.numberOfDependents,
      typeOfHousing: currFields.houseInfo?.precal || currFields.houseInfo,
      levelOfEducation:
        currFields.educationLevel?.precal || currFields.educationLevel,
    };
    if (
      currFields.salary &&
      currFields.entryDate &&
      currFields.numberOfDependents &&
      currFields.houseInfo &&
      currFields.educationLevel
    ) {
      const res = await fetchPrecalification(props.token, params);
      return res.data;
    }

    return;
  };

  const getParentKey = (childKey) => {
    let parentKey = "";
    for (const property in sections) {
      if (sections[property]?.fields?.includes(childKey)) {
        parentKey = property;
        break;
      }
    }
    return parentKey;
  };

  const getErrorsKeys = (errors, newResult) => {
    let result = newResult || {};
    for (const property in errors) {
      if (
        (Array.isArray(errors[property]) && errors[property].length === 1) ||
        errors[property] === true
      ) {
        const parentKey = getParentKey(property);
        if (result[parentKey]) {
          result[parentKey][property] =
            errors[property][0] || "Introduzca información.";
        } else {
          result[parentKey] = {
            [property]: errors[property][0] || "Introduzca información.",
          };
        }
      } else if (typeof errors[property] === "object") {
        getErrorsKeys(errors[property], result);
      }
    }
    return result;
  };

  useEffect(() => {
    // get required values;
    let rFields = [];
    Object.values(sections).forEach((item) => {
      rFields = rFields.concat(item.rFields);
      requiredFields.current = rFields;
    });
    getStatus();
  }, []);

  useEffect(() => {
    if (props.modify && props.data) {
      handleEditRow();
    }
  }, [props.modify]);

  useEffect(() => {
    getStatus();
  }, [props.data]);

  useEffect(() => {
    if (props.sectionErrors.errors) {
      const result = getErrorsKeys(props.sectionErrors.errors);
      if (Object.keys(result).length) {
        props.setSectionErrors((prevState) => ({
          ...prevState,
          formatted: result,
        }));
      }
    }
  }, [props.sectionErrors.errors]);

  useEffect(() => {
    if (Object.keys(errorFields).length) {
      props.setSectionErrors((prevState) => ({
        ...prevState,
        errors: { ...errorFields },
      }));
    }
  }, [errorFields]);

  useEffect(() => {
    const currFields = fields?.current;

    if (
      currFields.salary &&
      currFields.entryDate &&
      currFields.dependentsAmount &&
      currFields.housingType &&
      currFields.educationLevel
    ) {
      calcPrecalification();
    }
  }, [props.data]);

  useEffect(() => {
    handleFieldChange({ ["_state"]: props.data?._state })
  }, [props.data?._state])

  return (
    <>
      {props.modify && (
        <Grid
          container
          direction="row"
          spacing={2}
          justifyContent="space-between"
          style={{ padding: "10px 16px 15px 16px" }}
        >
          <Paper
            style={{
              width: "100%",
              padding: ".5rem 1rem",
              margin: "0 8px .5rem 8px",
            }}
          >
            <Typography
              variant="subtitle1"
              style={{
                color: "#424242",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {props.data?.institution || "---"}
              <Typography
                variant="overline"
                style={{ color: "rgb(97, 97, 97)" }}
              >
                Código: {props.data?.employerCode || "---"}
              </Typography>
            </Typography>
          </Paper>
          {props.isSuperUser && (
            <Paper
              style={{
                width: "100%",
                padding: ".5rem 1rem",
                margin: "0 8px .5rem 8px",
              }}
            >
              <AutocompleteField
                xs={4}
                fieldID="filter-field-states"
                fieldInnerLabel="Seleccione el estado"
                value={currentStatus}
                handleChange={(event, value) => {
                  modifyStatus(event, value);
                }}
                options={getFiltersOptions(statusColors)?.filter(
                  (item) => item.state !== "default"
                )}
                getOptionLabel={(option) => option.fullName}
                getOptionSelected={(option, value) =>
                  option.state === value.state
                }
                limitTags={1}
              />
              <Typography
                variant="overline"
                style={{ color: "rgb(97, 97, 97)" }}
              >
                Modificar Estado
              </Typography>
            </Paper>
          )}

          <Grid item xs={4}>
            <Card>
              <CardContent style={{ marginTop: "5px" }}>
                <Typography variant="subtitle1" style={{ color: "#424242" }}>
                  <div style={{ display: "table" }}>
                    <span>{props.data?.referenceCode || "- - - - -"}</span>
                  </div>
                  <Typography
                    variant="overline"
                    style={{ color: "#616161", fontSize: "11px" }}
                  >
                    Código Solicitud
                  </Typography>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card>
              <CardContent style={{ marginTop: "5px" }}>
                <Typography variant="subtitle1" style={{ color: "#424242" }}>
                  <div style={{ display: "table" }}>
                    <span>{props.data?.fullName || "- - - - -"}</span>
                  </div>
                  <Typography
                    variant="overline"
                    style={{ color: "#616161", fontSize: "11px" }}
                  >
                    Cliente
                  </Typography>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card>
              <CardContent style={{ marginTop: "5px" }}>
                <Typography
                  variant="subtitle1"
                  style={{ color: currentStatus.bgColor }}
                >
                  <div style={{ display: "table" }}>
                    <span>{currentStatus.name}</span>
                  </div>
                  <Typography
                    variant="overline"
                    style={{ color: "#616161", fontSize: "11px" }}
                  >
                    Estado
                  </Typography>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
      <Grid
        container
        direction="row"
        spacing={2}
        style={{ padding: "0 16px" }}
        className="section-forms-container"
      >
        {Object.keys(sections).map((key, index) => {
          const Section = sections[key].component;
          return (
            <React.Fragment key={`${key} - ${index}`}> 
              {!hiddenSections[key] && (
                <Layout
                  initialData={leadsData}
                  title={sections[key].label}
                  name={key}
                  errorFields={errorFields}
                  rFields={sections[key].rFields}
                  noWrapper={sections[key].noWrapper}
                  customer={props.data?.customer}
                  leadUniqueId={props.data?.uniqueId}
                  section={key}
                  institution={props.institution}
                  setInstitution={props.setInstitution}
                  bankInfo={props.bankInfo}
                  setBankInfo={props.setBankInfo}
                  onChange={handleFieldChange}
                  getFiltersOptions={getFiltersOptions}
                  calcDistributionChart={calcDistributionChart}
                  calcPrecalification={calcPrecalification}
                  expand={expand}
                  setExpand={setExpand}
                  setDisabledLead={props.setDisabledLead}
                  setHiddenSections={setHiddenSections}
                  setLoading={props.setLoading}
                  handleSimpleDialog={props.handleSimpleDialog}
                  options={props.generalData}
                  sectionErrors={props.sectionErrors.formatted}
                  token={props.token}
                  disabled={props.disabled}
                >
                  <Section />
                </Layout>
              )}
            </React.Fragment>
          );
        })}
      </Grid>
      <Grid
        container
        justifyContent="flex-end"
        style={{ padding: "32px 16px 0 0" }}
      >
        <Button
          style={{
            position: "absolute",
            left: -999,
            top: 0,
            visibility: "hidden",
          }}
          onClick={handleSaveBtn}
          ref={props.saveRef}
        >
          {props.modify ? "Guardar cambios" : "Crear solicitud"}
        </Button>
      </Grid>
    </>
  );
}

export function BankAccountDialog(props) {
  const { setFields, fields, open, handleClose, section, errorFields } = props;

  const filteredBankAccountTypes = bankAccountTypes.filter(opt => opt.value !== "cash");

  const handleChange = (field, event, val) => {
    let value = val ? val : event && event.target ? event.target.value : event;
    setFields({ ...fields, [field]: value });
  };

  const clearFields = () => {
    setFields({
      ...fields,
      financialInstitution: [],
      accountType: "",
      bankAccount: "",
    });
  };

  useEffect(() => {
    const fields = props.accountInfo
    if (fields.accountType && fields.bank && fields.number) {
      const FormatFields = async () => {
        const res = await fetchInstitutions({token: props.token,name: fields.bank.toLowerCase(), institutionType: 'financial'});
        const accountInfo = {
          financialInstitution: res.data[0],
          accountType: fields.accountType,
          bankAccount: fields.number
        }
        setFields(accountInfo);
      }
      FormatFields();
    }
  }, [props.accountInfo.accountType, props.accountInfo.bank, props.accountInfo.number]);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      onClose={(e, reason) => reason === "backdropClick" && e.preventDefault()}
      className="dialog-container"
      open={open}
    >
      <DialogHeader dialogTitle={"Cuenta bancaria"} handleClick={handleClose} />
      <DialogContent>
        <Typography variant="h6">Ingresar datos bancarios</Typography>
        <br />
        <Grid container spacing={3} alignItems="flex-end">
          <SearchComponent
            xs={4}
            containerClass="field-container"
            fieldID="filter-field-org"
            fieldInnerLabel="Institución financiera"
            handleChange={handleChange.bind(this, "financialInstitution")}
            onInputChange={() => {
              // gg
            }}
            savedValue={fields.financialInstitution}
            optionType={"banks"}
            // clearOnBlur={false}
            limitTags={1}
          />
          <Grid item xs={4}>
            <FormControl fullWidth margin="none">
              <Select
                value={fields?.accountType || "saving"}
                margin="dense"
                variant="outlined"
                onChange={handleChange.bind(this, "accountType")}
                style={{ width: 250 }}
                native={true}
                error={Boolean(errorFields[section]?.accountType)}
                inputProps={{
                  id: "accountType",
                  name: "accountType",
                }}
              >
                {filteredBankAccountTypes.map((opt, key) => {
                  return (
                    <option key={key} value={opt.value}>
                      {opt.label}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={4}>
            <TextField
              variant="outlined"
              margin="dense"
              label={translations.ACCOUNT_NUMBER}
              value={fields?.bankAccount}
              onChange={handleChange.bind(this, "bankAccount")}
              required={true}
              error={Boolean(errorFields[section]?.bankAccount)}
              fullWidth
              helperText={
                errorFields[section]?.bankAccount
                  ? errorFields[section]?.bankAccount
                  : null
              }
              InputProps={{
                inputComponent: accountNumberMaskInput,
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          style={{ marginTop: "35px", justifyContent: "space-between" }}
        >
          <Button
            disableElevation
            className="filter-clear-btn"
            onClick={clearFields.bind(this, false)}
          >
            limpiar
            <ClearAll style={{ marginLeft: 8, fontSize: "19px" }} />
          </Button>
          <Button
            disableElevation
            style={{
              background: green[500],
              padding: "0 50px",
              color: "white",
            }}
            onClick={handleClose}
          >
            Guardar
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
