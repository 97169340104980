import { Button, Grid, Paper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Filter from "./filters";
import { ToolbarComponent } from "src/components/SharedComponents/Toolbar";
import ClientTable from "./table";
import {
  fetchAssignees,
  fetchBusinessRepresentatives,
  fetchNationalities,
  fetchParticipantHistory,
  fetchParticipants,
  fetchProfessions,
} from "src/utils/axios";
import { connect } from "react-redux";
import ParticipantForm from "../ParticipantForm";
import ClientsDetailView from "../ClientsDetailView";
import ParticipantActivityHistory from "../ParticipantActivityHistory";
import { green } from "@material-ui/core/colors";
import { Description, Timeline } from "@material-ui/icons";
import LoadingDialog from "src/components/SharedComponents/LoadingScreen";
import { getRiskMatrix, nationalitiesToOptions, toOptions } from "src/utils";
import JwtDecode from "jwt-decode";

function ClientsMainSection(props) {
  const [loading, setLoading] = useState(false);
  const [clients, setClients] = useState([]);
  const [participantHistory, setParticipantHistory] = useState([]);
  //the view selected to show when toggle showDetail
  const [selection, setSelection] = useState("");
  //showDetail toggle the views between the new client form and the client details view
  const [showDetail, setShowDetail] = useState(false);
  const [historyDialog, setHistoryDialog] = useState(false);
  const [selectedClient, setSelectedClient] = useState({});
  //to change the info shown in the toolbar title when changing views
  const [toolbarTitle, setToolbarTitle] = useState("Gestión de Socios");
  //initial data managed in the client view
  const [initialData, setInitialData] = useState({
    nationalityOptions: [],
    currentUser: {},
    is_administration: false,
    users: [],
    professionOptions: [],
  });
  const [filters, setFilters] = useState({});
  const token = props.user.token;

  const fetchClients = async (params) => {
    const options = {
      ...params,
      ...filters,
      token: token,
    };
    try {
      setLoading(true);
      const response = await fetchParticipants(options);
      setClients(response.data.results ? response.data.results : response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error al cargar participants", error);
      setLoading(false);
    }
  };

  const openHistory = () => {
    setHistoryDialog(true);
  };

  const handleHistoryDialogClose = () => {
    setHistoryDialog(false);
  };

  const getParticipantHistory = async (clientId) => {
    try {
      setLoading(true);
      const res = await fetchParticipantHistory(token, clientId);
      const history = res.data;
      setParticipantHistory(history.historical_records);
      setLoading(false);
    } catch (error) {
      console.error("Error al obtener el historial del participante: ", error);
      setLoading(false);
    }
  };

  const RiskMatrix = async (client) => {
    try {
      setLoading(true);
      await getRiskMatrix(client.uniqueId, false, token, client.fullName);
      setLoading(false);
    } catch (error) {
      console.error("Error al generar la matriz de riesgo: ", error);
      setLoading(false);
    }
  };

  //refresh selected client when is updated in the client view
  const updateClient = (updatedClient) => {
    setSelectedClient(updatedClient);
    getParticipantHistory(updatedClient.uniqueId);
    fetchClients({ limit: 100 });
  };

  const handleToggleSelect = (select) => {
    const selected = typeof select === "string" ? select : "";
    setSelection(selected);
    setShowDetail(!showDetail);
    //reset participantHistory info when showDetail is false
    !showDetail && setParticipantHistory([]);
  };

  const showParticipant = (selected) => {
    setSelectedClient(selected);
    handleToggleSelect("client");
    getParticipantHistory(selected.uniqueId);
  };

  useEffect(() => {
    switch (selection) {
      case "client":
        setToolbarTitle(selectedClient.fullName || "Detalles del Socio");
        break;
      case "newclient":
        setToolbarTitle("Nuevo socio");
        break;
      default:
        setToolbarTitle("Gestión de Socios");
    }
  }, [selectedClient, selection]);

  const fetchInitialData = async () => {
    try {
      const userId = JwtDecode(token).user_id;

      const [
        nationalitiesResponse,
        assigneesResponse,
        businessRepresentativesResponse,
        professionsResponse,
      ] = await Promise.all([
        fetchNationalities({ token }),
        fetchAssignees({ token, id: userId }),
        fetchBusinessRepresentatives({ token }),
        fetchProfessions({ token }),
      ]);

      const nationalities = nationalitiesToOptions(
        nationalitiesResponse.data.results || nationalitiesResponse.data
      );
      const currentUser = assigneesResponse.data[0];
      const users = businessRepresentativesResponse.data.filter(
        (x) => x.isActive
      );
      const professionOptions = toOptions(
        professionsResponse.data.results || professionsResponse.data
      );
      const isAdministration = currentUser.groups.includes("administration");

      setInitialData({
        nationalityOptions: nationalities,
        currentUser,
        is_administration: isAdministration,
        users,
        professionOptions,
      });
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    fetchInitialData();
  }, []);

  useEffect(() => {
    fetchClients({ limit: 100 });
  }, [filters]);

  return (
    <Grid style={{ padding: "15px" }}>
      <LoadingDialog open={loading} />
      <ParticipantActivityHistory
        open={historyDialog}
        participantHistory={participantHistory}
        handleClose={handleHistoryDialogClose}
        token={token}
      />
      <ToolbarComponent
        typeVariant="h6"
        typeClass="title-text"
        toolbarTitle={toolbarTitle}
        btnVariant="contained"
        goBackColor="#424242"
        goBack={handleToggleSelect}
        showGoBack={showDetail}
        toolbarActions={
          !showDetail ? (
            <div>
              <Button
                variant="contained"
                className={"action-method-btn"}
                disableElevation
                size="small"
                onClick={handleToggleSelect.bind(this, "newclient")}
              >
                Agregar nuevo socio
              </Button>
            </div>
          ) : showDetail && selection == "client" ? (
            <Grid>
              <Button
                variant="outlined"
                disableElevation
                style={{
                  borderRadius: 3,
                  borderColor: green[500],
                  color: green[500],
                  fontSize: 12,
                  marginBottom: "1rem",
                }}
                onClick={openHistory}
              >
                REGISTRO DE ACTIVIDAD
                <Timeline style={{ fontSize: 17, marginLeft: ".5rem" }} />
              </Button>
              <Button
                variant="outlined"
                disableElevation
                style={{
                  borderRadius: 3,
                  backgroundColor: green[500],
                  color: "#fff",
                  fontSize: 12,
                  marginBottom: "1rem",
                  marginLeft: "1rem",
                }}
                onClick={() => RiskMatrix(selectedClient)}
              >
                GENERAR MATRIZ DE RIESGO
                <Description style={{ fontSize: 17, marginLeft: ".5rem" }} />
              </Button>
            </Grid>
          ) : null
        }
      />

      <div
        style={{
          padding: "1%",
          border: "1px solid #EEEEEE",
          borderTop: "none",
          display: showDetail ? "none" : "block",
        }}
      >
        <Filter
          filter={filters}
          setFilters={setFilters}
          representatives={initialData.users}
          professions={initialData.professionOptions}
        />
        <div style={{ margin: "10px 0" }}></div>
        <ClientTable data={clients} showParticipant={showParticipant} />
      </div>

      {showDetail && selection == "client" && (
        <div
          style={{
            border: "1px solid #EEEEEE",
            borderTop: "none",
          }}
        >
          <ClientsDetailView
            client={selectedClient}
            clientId={selectedClient.uniqueId}
            initialData={initialData}
            fetchClients={updateClient}
            setLoading={setLoading}
          />
        </div>
      )}

      {showDetail && selection == "newclient" && (
        <div
          style={{
            border: "1px solid #EEEEEE",
            borderTop: "none",
          }}
        >
          <ParticipantForm fetchClients={fetchClients} />
        </div>
      )}
    </Grid>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}
export default connect(mapStateToProps)(ClientsMainSection);
