import React, { useEffect, useState } from 'react';
import { 
    Grid, Dialog, Typography, Button
} from '@material-ui/core';
import { 
    AutocompleteField, TextFieldComponent, DateField, NumericTextField,
    SearchComponent, CheckboxField,
} from './../../../components/SharedComponents/Fields';
import { 
    SectionHeader
} from './../../../components/SharedComponents/GeneralComponents';
import { getFormatedStringFromDays } from './../../../components/SharedComponents/CustomHooks';
import { 
    fetchTime, fetchInstitution
 } from './../../../utils/axios';
import { 
    LABORAL_STATUS_OPTIONS, paymentTypesLeads, sessionTypes, 
    workTypes, paymentFrequency, economicSectors, economicActivity
} from '../../../utils/index';
import phoneMaskInput from '../../../components/TextMaskInputComponents/phoneMaskInput';
import InstitutionSelectInput from "src/containers/Institutions/InstitutionsSelectInput";
import { handleUploadFileEndpoint, getPossibleFileAttachment } from "../GeneralMethods";
import { FileSelection } from './../../../components/SharedComponents/FileSelection';

export default function WorkInfoForm({
    rFields, fields, onChange, errorFields, options, 
    section, getFiltersOptions, handleSelectValue, token,
    setInstitution, institution, setLoading, handleSimpleDialog,
    leadUniqueId, disabled
}){
    const [file, setFile] = useState('');
    const [savedFile, setSavedFile] = useState(fields.incomeVoucher || null);

    const getCurrentFile = async () => {
        const options = {
            token,
            file: fields.incomeVoucher,
            field: 'incomeVoucher',
            leadUniqueId
        }
        const currFile = await getPossibleFileAttachment(options);
        if(currFile) {
            onChange('any', 'incomeVoucher', currFile);
        }
    }

    const handleInstitution = (type, id) => {
        let value = [];
        switch(type) {
            case "add":
                value = id;
                break;
            case "remove":
                value = {};
                break;
            default:
                break;
        }
        // onChange('any', 'institution', value);
        setInstitution({uniqueId: value});
    }

    const handleInstitutionTotalTime = async () => {
        if(fields.entryDate) {
            const time = await fetchTime().then(response => response.data);
            const formattedTime = time.substring(0, time.indexOf("T"));
            const newTime = new Date(formattedTime);
            const newDate = new Date(fields.entryDate);
            const diffTime = Math.abs(newDate - newTime);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            const timeInInstitution = getFormatedStringFromDays(diffDays);
            onChange('any', 'institutionTime', timeInInstitution);
        }
    }

    const checkInstitutionFields = () => {

        const instFields = (
            fields?.institutionName ||
            fields?.institutionEmail ||
            fields?.institutionPhone ||
            fields?.institutionAddress ||
            fields?.rnc
        );
        return Boolean(instFields);
    }

    const changeValueFields = () => {
        onChange('any', 'workEmail', (fields.email || ''));
    }

    useEffect(() => {
        // getCurrentInstitution();
        changeValueFields();
    }, []);

    useEffect(() => {
        if(fields.institutionUniqueid) {
            setInstitution(ps => ({...ps, uniqueId: fields.institutionUniqueid}));
        }
    }, [fields.institutionUniqueid])

    useEffect(() => {
        if(fields.laboralStatus?.value === 'retired') {
            onChange('date', 'entryDate', null);
            onChange('date', 'entryDate', null);
        }
    }, [fields.laboralStatus]);

    useEffect(() => {
        handleInstitutionTotalTime();
    }, [fields.entryDate]);

    useEffect(() => {
        getCurrentFile();
    }, []);
    useEffect(() => {
        if(savedFile?.uniqueId !== fields.incomeVoucher?.uniqueId) {
            // onChange('any', 'incomeVoucher', savedFile);
            onChange('any', 'incomeVoucher', savedFile);
        }
    }, [savedFile]);

    return(
        <Grid container spacing={3} className="form-wrapper-fields-container">
            <SectionHeader 
                title={"Información de la empresa"}
            />

            <Grid xs={12} container item direction='row'>
                <div style={{width:'100%'}}>
                    <InstitutionSelectInput
                        selectedInstitutionId={institution?.uniqueId ? [institution.uniqueId] : []}
                        addInstitution={id => handleInstitution("add", id)}
                        deleteInstituion={id => handleInstitution("remove", id)}
                        handleSetInstitution={(data) => setInstitution({...data})}
                    />
                </div>
                    <Grid container item direction='row' style={{padding: '1rem 0'}}>
                        {/* checkInstitutionFields() && (!institution?.uniqueId) && */}
                        {   
                            (institution.registry === "000000000" || institution?.state !== 'affiliate') &&
                            <>
                                <Grid container item xs={12} spacing={3} style={{padding: '0 1rem'}}>
                                    <TextFieldComponent
                                        xs={4}
                                        fullWidth
                                        fieldLabel={`Nombre institución`}
                                        fieldID="leads-field-inst-name"
                                        placeholder="- - -"
                                        value={fields?.institutionName || ''}
                                        disabled={true}
                                    />
                                    <TextFieldComponent
                                        xs={4}
                                        fullWidth
                                        fieldLabel={`Correo institución`}
                                        fieldID="leads-field-inst-email"
                                        placeholder="- - -"
                                        value={fields?.institutionEmail || ''}
                                        disabled={true}
                                    />
                                    <TextFieldComponent
                                        xs={4}
                                        fullWidth
                                        fieldLabel= {`Teléfono institución`}
                                        fieldID="leads-field-inst-phoneNumber"
                                        placeholder="- - -"
                                        value={fields?.institutionPhone || ''}
                                        disabled={true}
                                    />
                                    <TextFieldComponent
                                        xs={4}
                                        fullWidth
                                        fieldLabel= {`Dirección institución`}
                                        fieldID="leads-field-inst-reference"
                                        placeholder="- - -"
                                        value={fields?.institutionAddress || ''}
                                        disabled={true}
                                    />
                                    <TextFieldComponent
                                        xs={4}
                                        fullWidth
                                        fieldLabel= {`RNC institución`}
                                        fieldID="leads-field-inst-rnc"
                                        placeholder="- - -"
                                        value={fields?.rnc || ''}
                                        disabled={true}
                                    />
                                    <AutocompleteField
                                        xs={4}
                                        fieldLabel= {`Sector económico`}
                                        fieldID="leads-field-day-type"
                                        value={
                                            handleSelectValue(fields.economicSector, economicSectors, 'value') || null
                                        }
                                        fieldInnerLabel="- - -"
                                        handleChange={onChange.bind(this, 'autocomplete', 'economicSector')}
                                        getOptionLabel={(option) => option.value }
                                        getOptionSelected={(option, value) => option.value === value.value}
                                        options={getFiltersOptions(economicSectors)}
                                        error={Boolean(errorFields[section]?.economicSector)}
                                        helperText={errorFields[section]?.economicSector ? errorFields[section]?.economicSector : null}
                                        disabled={true}
                                        multiple={false}
                                    />
                                    <TextFieldComponent
                                        xs={4}
                                        fullWidth
                                        fieldLabel= {`Actividad comercial ${rFields.includes("commercialActivity") ? "(*)" : ""}`}
                                        fieldID="leads-field-commercialActivity"
                                        value={fields.commercialActivity || ''}
                                        placeholder="- - -"
                                        onChange={onChange.bind(this, 'text', 'commercialActivity')}
                                        error={Boolean(errorFields[section]?.commercialActivity)}
                                        helperText={errorFields[section]?.commercialActivity ? errorFields[section]?.commercialActivity : null}
                                        disabled={true}
                                    />
                                </Grid>
                            </>
                        }
                    </Grid>
                
            </Grid>
            <SectionHeader 
                title={"Información colaborador"}
            />             
                <Grid container item xs={12} spacing={3} style={{padding:'.5rem 1rem'}}>
                    <DateField 
                        xs={4}
                        fullWidth
                        fieldLabel={`Fecha de Ingreso ${rFields.includes("entryDate") ? "(*)" : ""}`}
                        dateFieldID="payment-receipt-date-admission"
                        dateFieldLabel={`Ej: 20/09/2018`}
                        value={fields.entryDate ? fields.entryDate + " " : null}
                        handleChange={onChange.bind(this, 'date', 'entryDate')}
                        disabled={fields.laboralStatus?.value === 'retired' || disabled}
                        error={Boolean(errorFields[section]?.entryDate)}
                        helperText={errorFields[section]?.entryDate ? errorFields[section]?.entryDate : null}
                    />
                    <NumericTextField
                        xs={4}
                        fullWidth
                        fieldLabel= {`Salario/Ingreso promedio ${rFields.includes("salary") ? "(*)" : ""}`}
                        placeholder="Ej: $25000"
                        fieldID="leads-field-salary"
                        prefix="$"
                        thousandSeparator={true}
                        decimalScale={0}
                        fixedDecimalScale={true}
                        value={fields.salary || ''}
                        onChange={onChange.bind(this, 'numeric', 'salary')}
                        error={Boolean(errorFields[section]?.salary)}
                        helperText={errorFields[section]?.salary ? errorFields[section]?.salary : null}
                        disabled={disabled}
                    />
                    <TextFieldComponent
                        xs={4}
                        fullWidth
                        fieldLabel= {`Tiempo en la compañía ${rFields.includes("institutionTime") ? "(*)" : ""}`}
                        fieldID="leads-field-institutionTime"
                        placeholder="1 año"
                        value={fields.institutionTime || ''}
                        onChange={onChange.bind(this, 'text', 'institutionTime')}
                        disabled={true}
                    />
                    <AutocompleteField
                        xs={4}
                        fieldLabel= {`Forma de Pago ${rFields.includes("paymentType") ? "(*)" : ""}`}
                        fieldID="leads-field-payment-form"
                        fieldInnerLabel="Seleccione forma de pago"
                        value={
                            // fields.paymentType || null
                            handleSelectValue(fields.paymentType, paymentTypesLeads, 'value')
                            || null
                        }
                        handleChange={onChange.bind(this, 'autocomplete', 'paymentType')}
                        getOptionLabel={(option) => option.label }
                        getOptionSelected={(option, value) => option.value === value.value}
                        options={getFiltersOptions(paymentTypesLeads)}
                        error={Boolean(errorFields[section]?.paymentType)}
                        helperText={errorFields[section]?.paymentType ? errorFields[section]?.paymentType : null}
                        multiple={false}
                        disabled={disabled}
                    />
                    <AutocompleteField
                        xs={4}
                        fieldLabel= {`Tipo de Empleo ${rFields.includes("workType") ? "(*)" : ""}`}
                        fieldID="leads-field-workType"
                        fieldInnerLabel="Seleccione tipo empleo"
                        value={
                            // fields.workType || null
                            handleSelectValue(fields.workType, workTypes, 'value')
                            || null
                        }
                        handleChange={onChange.bind(this, 'autocomplete', 'workType')}
                        getOptionLabel={(option) => option.label }
                        getOptionSelected={(option, value) => option.value === value.value}
                        options={getFiltersOptions(workTypes)}
                        error={Boolean(errorFields[section]?.workType)}
                        helperText={errorFields[section]?.workType ? errorFields[section]?.workType : null}
                        multiple={false}
                        disabled={disabled}
                    />
                    <AutocompleteField
                        xs={4}
                        fieldLabel= {`Tipo de Jornada ${rFields.includes("sessionType") ? "(*)" : ""}`}
                        fieldID="leads-field-day-type"
                        fieldInnerLabel="Seleccione tipo de jornada"
                        value={
                            // fields.sessionType || null
                            handleSelectValue(fields.sessionType, sessionTypes, 'value')
                            || null
                        }
                        handleChange={onChange.bind(this, 'autocomplete', 'sessionType')}
                        getOptionLabel={(option) => option.label }
                        getOptionSelected={(option, value) => option.value === value.value}
                        options={getFiltersOptions(sessionTypes)}
                        error={Boolean(errorFields[section]?.sessionType)}
                        helperText={errorFields[section]?.sessionType ? errorFields[section]?.sessionType : null}
                        multiple={false}
                        disabled={disabled}
                    />
                </Grid>
        </Grid>
    )
}